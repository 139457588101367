import { render, staticRenderFns } from "./SbProContent.vue?vue&type=template&id=37c7e7c0"
import script from "./SbProContent.vue?vue&type=script&lang=js"
export * from "./SbProContent.vue?vue&type=script&lang=js"
import style0 from "./SbProContent.vue?vue&type=style&index=0&id=37c7e7c0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SbContent: require('/tmp/build_7e029ab6/components/storyblok/SbContent.vue').default})
