
import { mapGetters } from 'vuex'

import Scrollto from '@/mixins/scrollto-mixin'
import SbContent from '@/components/storyblok/SbContent'

export default {
  name: 'SbProContent',
  components: {
    SbContent,
  },
  mixins: [Scrollto],
  props: {
    slug: { type: String, default: 'home' },
  },
  computed: {
    ...mapGetters('proArea', ['getStoryBySlug']),
    rawProArea() {
      return this.getStoryBySlug(this.slug)
    },
  },
}
