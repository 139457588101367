
/*
  If Tld is FR, we call SbProContent with slug "home", the content is call in SbProContent component
  if not, we call default SbContent, and storyblok API to get content
 */
import SbContent from '@/components/storyblok/SbContent'
import SbProContent from '@/components/storyblok/SbProContent'
import i18nMx from '@/mixins/i18n-mixin'

export default {
  name: 'ProIndex',
  key: ({ fullPath }) => fullPath,
  auth: false,
  components: {
    SbContent,
    SbProContent,
  },
  mixins: [i18nMx],
  layout: 'pro',
  middleware: ['pro-area-store-load'],
  data() {
    return {
      content: null,
      lang: null,
      title: null,
      imagePath: null,
      description: null,
    }
  },
  fetchOnServer: true,
  async fetch() {
    let tld = this.$store.state['i18nStore']?.tld
    // Match fr or FR
    if (!tld.match(/fr/i)) {
      try {
        let date = new Date()
        let langCode = this.$store.getters['i18nStore/langCode'](true)
        let locale = langCode === 'fr' ? 'default' : langCode
        const version = process.env.STORYBLOK_ENV === 'production' ? 'published' : 'draft'
        const lp = await this.$storyapi.get(`cdn/stories/espace-pro/benelux-lp`, {
          version,
          cv: process.env.STORYBLOK_ENV === 'production' ? date.setHours(date.getHours(), 0, 0, 0) : Date.now(),
          language: locale,
        })
        this.content = lp.data?.story?.content
        this.lang = lp.data?.story?.lang
      } catch (e) {
        if (e.response && e.response.status === 404) {
          this.$router.replace(this.app.localePath('/'))
        }
        this.title = 'LP not found'
        this.imagePath = null
        this.description = null
      }
    } else {
      this.content = null
    }
  },
  computed: {
    lpContent() {
      return this.content
    },
  },
  mounted() {
    this.$analytics.sendScreen(this.$pageTemplate.PRO_HOMEPAGE)
  },
}
